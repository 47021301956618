<template>
	<v-card class="mx-auto mb-5" max-width="1200" rounded="xl">
		<v-toolbar>
			<v-avatar size="45px" class="mr-2">
				<v-img alt="Avatar" :src="notice.author.imgURL" />
			</v-avatar>
			<v-toolbar-title>
				<div class="d-flex flex-column">
					<span class="title">{{ notice.author.name }}</span>
					<span class="subtitle-1">{{ notice.course }}</span>
				</div>
			</v-toolbar-title>
			<v-spacer />
			<v-menu bottom left offset-y v-if="notice.author.bool">
				<template v-slot:activator="{ on }">
					<v-btn :small="$vuetify.breakpoint.smAndDown" icon v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-list color="transparent" class="pa-0" rounded dense>
						<v-list-item @click="editMode()">
							<v-list-item-title>{{ $t('settings.edit') }}</v-list-item-title>
						</v-list-item>
					</v-list>
					<v-list-item @click="callDeleteNotice()" dense class="red darken-1">
						<v-list-item-title>{{ $t('settings.delete') }}</v-list-item-title>
					</v-list-item>
				</v-card>
			</v-menu>
		</v-toolbar>

		<template v-if="!editing">
			<v-card-title>{{ notice.title }}</v-card-title>
			<v-card-subtitle>
				<time :datetime="notice.timestamp">{{ humanTime }}</time>
			</v-card-subtitle>
			<v-card-text class="body-1" style="word-break: break-all; white-space: pre-line">
				{{ notice.content }}
			</v-card-text>
		</template>
		<template v-else>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field
						v-model="title"
						type="text"
						:label="$t('newsfeed.title')"
						:placeholder="$t('newsfeed.titleToRemember')"
						:rules="rules.title"
						outlined
						rounded
						hide-details="auto"
						class="pb-2"
					/>
					<v-textarea
						v-model="content"
						:label="$t('newsfeed.content')"
						:placeholder="$t('newsfeed.whatAreYouThinking')"
						:rows="1"
						:rules="rules.content"
						:counter="contentMaxLength"
						auto-grow
						outlined
						rounded
						hide-details="auto"
						class="pb-2"
					/>
					<div class="mx-auto d-flex align-center justify-center flex-wrap pt-0 pb-5">
						<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callEditPost()">
							{{ $t('settings.edit') }}
						</v-btn>
					</div>
				</v-form>
			</v-card-text>
		</template>

		<v-card-actions>
			<v-icon>mdi-thumb-up-outline</v-icon>
			<v-badge class="mr-2" :content="thumbsUp ? thumbsUp : '0'" :value="thumbsUp ? thumbsUp : '0'" inline overlap />

			<v-icon>mdi-thumb-down-outline</v-icon>
			<v-badge :content="thumbsDown ? thumbsDown : '0'" :value="thumbsDown ? thumbsDown : '0'" inline overlap />
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FeedNotice',
	props: {
		notice: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			thumbsUp: this.notice.thumbsUp,
			thumbsDown: this.notice.thumbsDown,
			comments: this.notice.comments,
			editing: false,
			loading: false,
			valid: false,
			title: this.notice.title,
			content: this.notice.content,
			rules: {
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength })
				],
				content: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.contentMaxLength) || this.$t('rules.length', { length: this.contentMaxLength })
				]
			}
		}
	},
	computed: {
		humanTime() {
			return this.$moment(this.notice.timestamp).fromNow()
		},
		titleMaxLength() {
			return 64
		},
		contentMaxLength() {
			return 1024 * 10
		},
		...mapGetters({
			setupInfo: 'user/setupInfo'
		})
	},
	methods: {
		editMode() {
			this.editing = !this.editing
		},
		callEditNotice() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editNotice({
				notice: this.notice,
				title: this.title,
				content: this.content
			})
				.then(({ success, notice }) => {
					if (success) {
						this.editMode()
						this.title = notice.title
						this.content = notice.content
						this.notice.title = notice.title
						this.notice.content = notice.content
						this.$refs.form.resetValidation()
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		callDeleteNotice() {
			this.deleteNotice(this.notice)
		},
		...mapActions('feed', ['editNotice', 'deleteNotice'])
	}
}
</script>
